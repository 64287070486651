import { getServerSession } from "next-auth";
import { authOptions } from "../app/api/(authentication)/auth/[...nextauth]/route";
import { redirect } from "next/navigation";
import fetchBase, { FetchParams } from "@/Services/fetchBase";
import AuthService from "@/Services/AuthService";

const serverFetch = async  (params: FetchParams): Promise<any> => {
  const { url, options, includeAccessToken } = params;

  let accessToken: string | undefined;
  if (includeAccessToken) {
    const session = await getServerSession(authOptions);
    if(!session) {
      redirect('/login')
    }
    accessToken = session ? session.accessToken : undefined;
  }
  const response = await fetchBase({ url, options, accessToken });
  // refresh token rotation from getServerSession failed and it somehow got here
  if (response.status === 401) {
      await AuthService.logout();
      return { error: 'Unauthorized' };
  }
  return response;
}

export default serverFetch;