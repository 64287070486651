'use client';
import { redirect } from "next/navigation";
import fetchBase, { FetchParams } from "@/Services/fetchBase";
import { Session } from "next-auth";
import AuthService from "@/Services/AuthService";
import { signIn, useSession } from "next-auth/react";

const clientFetch = async (params: FetchParams, session: Session | null, update: () => Promise<Session | null>, retryCount = 0): Promise<any> => {
  const { url, options, includeAccessToken } = params;
  let accessToken: string | undefined;
  if (includeAccessToken) {
    if(!session) {
      redirect('/login')
      return {error: 'No session found'}
    }
    accessToken = session ? session.accessToken : undefined;
  }

  const response = await fetchBase({ url, options, accessToken }).then((res) => {
    console.log('res', res)
    return res
  }).catch((e) => {
    console.log('error', e)
    return {error: e, status: 401}
  });
  if (response.status === 401) {
    // Call signIn() and refetch the original
    console.log('401')
    // Check if this is the second failure
    if (retryCount > 0) {
      // If the second attempt fails, call logOut()
      console.log('401 retry failed')
      await AuthService.logout();
      return { error: 'Unauthorized' };
    }
    else {
      console.log('401 retrying')
      try {
        const newSession = await update()

        // Re-attempt the fetch with an incremented retry count
        return await clientFetch(params, newSession, update, retryCount + 1);
        
      } catch (e) {
        console.log('error', e)
        return {error: e, status: 401}
      }
      
    }
  }
  return response;
}

export default clientFetch;