import { v4 as uuidv4 } from 'uuid';
import type { ILanguage, IVoice } from "../models/language";
import {User} from "../models/user";
import {Spanish} from "../Helpers/international";

const defaultLanguage = Spanish;

export const defaultVoice = {
    voice_id: "Arthur",
    country: "US",
    language: "English",
    dialect: "British"
}

export const defaultDocOffset = "1--0"
export const UserThemeKey = "ThemePreference"
export const UserIdKey = "X-Vocalini-ID"

let temporaryKey = ""

export function getUser(): User | null {
    if (typeof localStorage == undefined) {
        return null;
    }
    const user = localStorage?.getItem("user");
    if (user) {
      try {
        return JSON.parse(user) as User;
      } catch(err) {}
    }
    return null;
};

export function hasSegmentIdentity(){
    const segmentTraits = localStorage.getItem("ajs_user_traits"); // MARK: set by segment
    return Boolean(segmentTraits)
};

export function setLocalStorageUser(user: User){
    localStorage && localStorage.setItem("user", JSON.stringify(user));
};

export function getUserPreferredTheme(){
    return localStorage.getItem(UserThemeKey)
};

export function setUserPreferredTheme(theme: string){
    localStorage && localStorage.setItem(UserThemeKey, theme);
};

export function getAnonUserId(){
    if (typeof localStorage == undefined) {
        if (temporaryKey) return temporaryKey
        temporaryKey = uuidv4();
        return temporaryKey
    }
    let user_id = localStorage.getItem(UserIdKey) || '';
    if (!user_id) {
        if (!temporaryKey) temporaryKey = uuidv4();
        user_id = temporaryKey;
        localStorage.setItem(UserIdKey, user_id)
    }
    return user_id
}

export function setUserPreferredLanguage(language: ILanguage){
    localStorage && localStorage.setItem("User-Preferred-Language", JSON.stringify(language));
}

export function getUserPreferredLanguage(){
    try {
        return JSON.parse(localStorage.getItem("User-Preferred-Language") || JSON.stringify(defaultLanguage)) as ILanguage
    } catch (err) {}
    return defaultLanguage
}

export function setUserPreferredVoice(voice: IVoice){
    localStorage && localStorage.setItem("User-Preferred-Voice", JSON.stringify(voice));
}

export function getUserPreferredVoice(){
    try {
        return JSON.parse(localStorage.getItem("User-Preferred-Voice") || JSON.stringify(defaultVoice)) as IVoice
    } catch (err) {}
    return defaultVoice
}

export function generateDocOffsetKey(user_id: string, doc_id: string){
    return `vl_${user_id}_${doc_id}`;
}

export function getEpubHref(doc_id: string){
    const key = `vl_href_${doc_id}`;
    const raw = localStorage?.getItem(key) || '{}';
    try {
        return JSON.parse(raw);
    }
    catch (err) {}
    return {}
}

export function setEpubHref(doc_id: string, href: string, cfi: string){
    const key = `vl_href_${doc_id}`;
    const value = JSON.stringify({href, cfi});
    localStorage && localStorage.setItem(key, value);
}

export function setLocalStorageOffsetForDocument(doc_id: string, doc_offset: string){
    const user_id = getAnonUserId();
    const key = generateDocOffsetKey(user_id, doc_id);
    localStorage && localStorage.setItem(key, doc_offset);
}

export function getLocalStorageOffsetForDocument(doc_id: string){
    const user_id = getAnonUserId();
    const key = generateDocOffsetKey(user_id, doc_id);
    return localStorage?.getItem(key) || defaultDocOffset;
}

export function generateChatKey(user_id: string, doc_id: string){
    return `vl_${user_id}_${doc_id}_chat`;
}

export function setLocalStorageOffsetForDocumentChat(doc_id: string | undefined, chat_history: any[]){
    if(!doc_id){
        console.log('undefined doc_id in setLocalStorageOffsetForDocumentChat');
        return;
    }
    const user_id = getAnonUserId();
    const chat_history_string = JSON.stringify(chat_history);
    const key = generateChatKey(user_id, doc_id);
    localStorage && localStorage.setItem(key, chat_history_string);
}

export function getLocalStorageOffsetForDocumentChat(doc_id: string){
    const user_id = getAnonUserId();
    const key = generateChatKey(user_id, doc_id);
    const chat_history = localStorage?.getItem(key);
    if (!chat_history) return [];
    try {
        return JSON.parse(chat_history);
    } catch (err) {
        console.error(`Failed to parse chat history`, err);
    }
    return [];
}

export function removeLocalStorageOffsetForDocumentChat(doc_id: string){
    const user_id = getAnonUserId();
    const key = generateChatKey(user_id, doc_id);
    localStorage && localStorage.removeItem(key);
}
