export const LocalWellKnownKeys = [
  {
    alg: 'RS256',
    e: 'AQAB',
    kid: '3UtslMHO3pX3E4btozQ/t5mvHCAdfkd9K4Y1IHGPR0Q=',
    kty: 'RSA',
    n: 'tiayShVYdFD42coeJ7Tgsyv749SQ8JV5rKm9S_huNMFWoLNXJWFj1ClsjOC4i_yzW2j-koF_jz_DuSvrRw9hxMhMaC2nmTBYycdXO_-GMLlnXKmXaKvOI2ZBan9tkjevx05f9czqfIlFkB_ruI97NLgChDTvadCyXC4oeSgmLPMWARJxaLW2H-HRcIjCv3HPXMQehXCsk69WqMfeEYXuZLhNreOHnmBYOx7StPVFhq10d4lJzW4YyRsLJ8UWa4ceiQbZgvUDedzYrfGLPU2ZGp3dawZOfzywJCG1byIGK4dSO1PuxGcjJPJTo5Zz7p_AZkPFiowMxs22ZW5rDM1x-Q',
    use: 'sig',
  },
  {
    alg: 'RS256',
    e: 'AQAB',
    kid: 'uJxJhFmO/G9SG8xJaU0qVZuyynloI/TqVX0vtIGMcMU=',
    kty: 'RSA',
    n: 'wzYGDfC6YRxl7z7iJNtjZpVp_sIQmNm_3Ty8tyCYPIhbtpavdb21LQFnRd0-IjKEyU6wCs9saoi5p-Tu7Vzlzo_8JQpRaqWjZtWkkxREUeVSs9UBfdzC0536w484yGNSXzoJLf06PUJqJvJ_wFYHuYhyGDFxc4W9-bE1-RYuEmBn2ZaibkDsQdilRkQgBgrYG3KbugJo33akWIdOk7EZkVyj9_MtdQ1bRE_nOCSJBbd5aYscwRl-EqzcMJiLlyFzQrox8ND2N7nOfnD1gT30mEL8IR7VcIc8IwRbfjPwsAqnaGry2nu_at5-U-TPguHIRlAlDdX_YDaKRKbvhxsrHQ',
    use: 'sig',
  },
];

const validateEnv = () => {
  if (
    !(
      process.env.NEXT_PUBLIC_AWS_REGION &&
      process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID
    )
  ) {
    throw new Error(
      `Invalid configuration: Missing AWS_REGION and COGNITO_USER_POOL_ID`
    );
  }
};

export const getCognitoBaseUrl = () => {
  validateEnv();
  return `https://cognito-idp.${process.env.NEXT_PUBLIC_AWS_REGION}.amazonaws.com/${process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID}`;
};

export const fetchWellKnownKeys = async () => {
  const keysUrl = `${getCognitoBaseUrl()}/.well-known/jwks.json`;
  try {
    const res = await fetch(keysUrl);
    const data = await res.json();
    return data.keys;
  } catch (error) {
    console.error('Failed to fetchWellKnownKeys', error);
  }
  return null;
};

// MARK: refreshToken should be http-only
export const getAccessTokenFromRefreshToken = async (refreshToken: string) => {
  if (!refreshToken) {
    console.error('Cannot refresh without a refreshToken');
    return null; // MARK: must return null for serialization
  }
  // see https://github.com/aws-amplify/amplify-js/blob/01a5b84ea010f7fb66c4e19e73301cce82fc7370/packages/amazon-cognito-identity-js/src/CognitoUser.js#L1472
  const data = {
    ClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
    AuthFlow: 'REFRESH_TOKEN_AUTH',
    AuthParameters: {
      REFRESH_TOKEN: refreshToken,
    },
  };
  const url = `https://cognito-idp.us-east-1.amazonaws.com/`;
  let accessToken = '';
  try {
    console.log('Going to try a cognito-idp refresh');
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      // credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'amz-sdk-request': 'attempt=1; max=3',
        'Content-Type': 'application/x-amz-json-1.1',
        'x-amz-target': 'AWSCognitoIdentityProviderService.InitiateAuth',
        'x-amz-user-agent':
          'aws-sdk-js/3.272.0 os/macOS/10.15.7 lang/js md/browser/Chrome_109.0.0.0 api/cognito_identity_provider/3.272.0',
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    const payload = await response.json();
    console.log(`received payload`, payload);
    accessToken = payload.AuthenticationResult?.AccessToken;
    console.log(`received accessToken: ${accessToken}`);
  } catch (err) {
    console.error(`Failed to refresh token!`, err);
  }
  return accessToken;
};
