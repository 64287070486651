import type { IVoice, ILanguage } from '../models/language';

export const English: ILanguage = { code: 'US', label: 'English' };
export const Spanish: ILanguage = { code: 'MX', label: 'Spanish' };

export const languages: readonly ILanguage[] = [
  English,
  Spanish,
  { code: 'UK', label: 'English'},
  { code: 'KR', label: 'Korean' },
  { code: 'CN', label: 'Chinese' },
  { code: 'FR', label: 'French' },
  { code: 'DE', label: 'German' },
  { code: 'AE', label: 'Arabic' },
  { code: 'RU', label: 'Russian' },
  { code: 'BR', label: 'Portuguese' },
  { code: 'IL', label: 'Hebrew' },
  { code: 'IR', label: 'Farsi' },
  { code: 'AM', label: 'Armenian' },
  { code: 'CZ', label: 'Czech' },
  { code: 'DK', label: 'Denmark' },
  { code: 'NL', label: 'Netherlands' },
  { code: 'SI', label: 'Slovenia' },
  { code: 'NO', label: 'Norway' },
  { code: 'RO', label: 'Romania' },
  { code: 'HU', label: 'Hungarian' },
];

// MARK: only standard voice for now...
export const voices: readonly IVoice[] = [
  {
    voice_id: 'Arthur',
    country: 'UK',
    language: 'English',
    dialect: 'British',
  },
  {
    voice_id: 'Emma',
    country: 'UK',
    language: 'English',
    dialect: 'British',
  },
  {
    voice_id: 'Brian',
    country: 'UK',
    language: 'English',
    dialect: 'British',
  },
  {
    voice_id: 'Amy',
    country: 'UK',
    language: 'English',
    dialect: 'British',
  },
  {
    voice_id: 'Joanna',
    country: 'US',
    language: 'English',
  },
  {
    voice_id: 'Stephen',
    country: 'US',
    language: 'English',
  },
  {
    voice_id: 'Jenny',
    country: 'US',
    language: 'English',
  },
  {
    voice_id: 'Ruth',
    country: 'US',
    language: 'English',
  },
  {
    voice_id: 'Mia',
    country: 'MX',
    language: 'Spanish',
    dialect: 'Mexican',
  },
  {
    voice_id: 'Lupe',
    country: 'MX',
    language: 'Spanish',
    dialect: 'US',
  },
  {
    voice_id: 'Vlasta',
    country: 'CZ',
    language: 'Czech',
  },
  {
    voice_id: 'Antonin',
    country: 'CZ',
    language: 'Czech',
  },
  {
    voice_id: 'Jeppe',
    country: 'DK',
    language: 'Danish',
  },
  {
    voice_id: 'Christel',
    country: 'DK',
    language: 'Danish',
  },
  {
    voice_id: 'Maarten',
    country: 'NL',
    language: 'Dutch',
  },
  {
    voice_id: 'Colette',
    country: 'NL',
    language: 'Dutch',
  },
  {
    voice_id: 'Lea',
    country: 'FR',
    language: 'French',
  },
  {
    voice_id: 'Mathieu',
    country: 'FR',
    language: 'French',
  },
  {
    voice_id: 'Anahit',
    country: 'AM',
    language: 'Armenian',
  },
  {
    voice_id: 'Karina',
    country: 'PR',
    language: 'Puerto Rican Spanish',
  },
  {
    voice_id: 'Vicki',
    country: 'DE',
    language: 'German',
  },
  {
    voice_id: 'Hans',
    country: 'DE',
    language: 'German',
  },
  {
    voice_id: 'Bianca',
    country: 'IT',
    language: 'Italian',
  },
  {
    voice_id: 'Giorgio',
    country: 'IT',
    language: 'Italian',
  },
  {
    voice_id: 'Tatyana',
    country: 'RU',
    language: 'Russian',
  },
  {
    voice_id: 'Maxim',
    country: 'RU',
    language: 'Russian',
  },
  {
    voice_id: 'Petra',
    country: 'SI',
    language: 'Slovenian',
  },
  {
    voice_id: 'Rok',
    country: 'SI',
    language: 'Slovenian',
  },
  {
    voice_id: 'Finn',
    country: 'NO',
    language: 'Norweigan',
  },
  {
    voice_id: 'Iselin',
    country: 'NO',
    language: 'Norweigan',
  },
  {
    voice_id: 'Alina',
    country: 'RO',
    language: 'Romanian',
  },
  {
    voice_id: 'Emil',
    country: 'RO',
    language: 'Romanian',
  },
  {
    voice_id: 'Noemi',
    country: 'HU',
    language: 'Hungarian',
  },
  {
    voice_id: 'Tamas',
    country: 'HU',
    language: 'Hungarian',
  },
  {
    voice_id: 'Camila',
    country: 'BR',
    language: 'Portuguese',
    dialect: 'Brazilian',
  },
  {
    voice_id: 'Seoyeon',
    country: 'KR',
    language: 'Korean',
  },
  {
    voice_id: 'Takumi',
    country: 'JP',
    language: 'Japanese',
  },
  {
    voice_id: 'Zeina',
    country: 'AE',
    language: 'Arabic',
  },
  {
    voice_id: 'Zhiyu',
    country: 'CN',
    language: 'Chinese',
    dialect: 'Mandarin',
  },
  {
    voice_id: 'Dilara',
    country: 'IR',
    language: 'Farsi / Persian',
  },
  {
    voice_id: 'Avri',
    country: 'IL',
    language: 'Hebrew',
  },
  {
    voice_id: 'Hila',
    country: 'IL',
    language: 'Hebrew',
  },
];

export const findVoice = (lang: string) => {
  const match = voices.find(o => o.language === lang);
  if (!match) {
    console.error(`Could not find language ${lang}`);
    return '';
  }
  return match.voice_id;
};
const fakeTranslateWord = (text: string) => {
  const regex = /[aeiou]/i;
  let firstVowelIndex = text.search(regex);
  switch (firstVowelIndex) {
    case 0:
      return `${text}way`;
    default:
      return (
        text.substring(firstVowelIndex) +
        text.substring(0, firstVowelIndex) +
        'ay'
      );
  }
};

export const fakeTranslate = (text: string) => {
  const segmenter = new Intl.Segmenter('en', { granularity: 'word' });
  let arr = Array.from(segmenter.segment(`${text} `), x => x.segment);
  let translations = [];
  for (let i = 0; i < arr.length - 1; i++) {
    const word = arr[i];
    let replacement = word;
    if (word.match(/^[a-zA-Z]/)) {
      replacement = fakeTranslateWord(word);
    }
    translations.push(replacement);
  }
  return translations.join('');
};
