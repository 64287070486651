export type FetchOptions = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: Record<string, string>;
  body?: Record<string, any>;
  queryParams?: Record<string, string>;
  cache?: 'no-cache' | 'no-store' | 'default' | 'reload' | 'force-cache' | 'only-if-cached';
  next?: NextFetchRequestConfig;
};

export type FetchParams = {
  url: string;
  options?: FetchOptions;
  includeAccessToken?: boolean;
  accessToken?: string
}

const fetchBase = async (params: FetchParams) => {
  const {url, options = {}, accessToken} = params;
  const { 
    method = 'GET', 
    headers = {}, 
    body, 
    queryParams, 
    cache, 
    next 
  } = options;

  // Construct query parameters if provided
  const queryString = queryParams
    ? '?' +
    Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
    : '';

  // Merge with default headers, such as authorization or content-type
  const finalHeaders = {
    'Content-Type': 'application/json',
    ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    ...headers,
  };
  let response;
  try{

    response = await fetch(url + queryString, {
      method,
      headers: finalHeaders,
      body: body ? JSON.stringify(body) : undefined,
      cache: cache,
      next,
    });
    
  } catch (e: any) {
    console.error('[URL]', url + queryString);
    console.error('Network response was not ok', e);
    throw new Error('Network response was not ok', e);
  }

  const resp = await response.json();
  return resp;
};

export default fetchBase;
