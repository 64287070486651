import { useFetchWrapper } from "../Helpers/fetchWrapper";
import ConfigFactory from "../Helpers/ConfigFactory";
import { FetchParams } from "@/Services/fetchBase";
import clientFetch from "@/Services/clientFetch";
import { Session } from "next-auth";

/**
 *This function should be able to delete a given document from the server
 * using the user's jwt token and a document_id
 **/
export default async function deleteDocument(doc_id: string, session: Session, update: any) {
  const endpoint = ConfigFactory.getBackendEndpoint() + "/deleteDocument";
  const body = {
    doc_ids: [doc_id],
  };
  const params: FetchParams = {
    url: endpoint,
    options: {
      method: 'POST',
      body: body,
      cache: 'no-cache',
    },
    includeAccessToken: true,
  }
  const resp = await clientFetch(params, session, update)
  return resp;
}
