export interface Document {
  id: string;
  title: string;
  access: string;
  added: number;
  image: string;
  status: string;
  type: string;
  parent: string;
  user_id: string;
  last_opened_time: number;
}

export enum DocumentType {
  TextUpload = 'text_input',
  UrlUpload = 'url',
  PdfUpload = 'pdf',
  EpubUpload = 'epub',
  ImageUpload = 'image',
  YoutubeUpload = 'youtube',
  Summary = 'outline',
  DocUpload = 'doc',
  DocxUpload = 'docx',
  PptUpload = 'ppt',
  PptxUpload = 'pptx',
  RtfUpload = 'rtf',
  TxtFileUpload = 'txt',
  OdpUpload = 'odp',
}

export const file_uploads = [
  DocumentType.DocUpload,
  DocumentType.DocxUpload,
  DocumentType.PptUpload,
  DocumentType.PptxUpload,
  DocumentType.RtfUpload,
  DocumentType.TxtFileUpload,
  DocumentType.OdpUpload,
];

export enum DisplayType {
  PDF = 'pdf',
  DOC = 'doc',
  SCAN = 'scan',
}

export enum DocumentAccess {
  Private = 'private',
  Public = 'public',
}

export enum DocumentStatus {
  Processing = 'processing',
  Ready = 'ready',
}

export interface BackendDocument {
  doc_type: DocumentType;
  document_id: string;
  doc_title: string;
  doc_url?: string;
  user_id?: string;
  parent_folder?: string;
  doc_access?: 'private' | 'public';
  date_added: number;
  doc_image?: string;
  doc_status?: string;
  doc_content?: string;
  description: '';
  num_pages?: number;
  last_opened_time?: number;
  doc_offset?: string;
  encoding?: string;
  mimetype?: string;
  size?: string | number;
  detected_lang?: string;
  queue_url?: string;
}

export interface TextDocument extends BackendDocument {
  doc_type: DocumentType.TextUpload;
  doc_content: string;
}

export interface PdfDocument extends BackendDocument {
  doc_type: DocumentType.PdfUpload;
  /** @property url: S3 bucket url */
  url: string;
}

export interface ProcessedDocument extends BackendDocument {
  date_added: number;
  doc_status: DocumentStatus;
  doc_access: DocumentAccess;
  doc_image?: string;
  num_pages?: number;
  doc_offset?: string;
  last_opened_time?: number;
  detected_lang?: string;
}

export interface ProcessedUrlDocument extends ProcessedDocument {
  doc_published_date: Date;
  doc_content: string;
  doc_descr: string;
  doc_src: string;
}

export interface ProcessedPdfDocument extends ProcessedDocument {
  encoding: string;
  mimetype: string;
  size: string | number;
}

export interface ProcessedOutline {
  doc_type: DocumentType;
  document_id: string;
  doc_title: string;
  parent_folder?: string;
  doc_status: DocumentStatus;
  doc_content: string;
  user_id?: string;
  doc_status_timestamp?: number;
}

export interface ProcessedDocumentBlob {
  doc_data: any;
  doc_obj: ProcessedUrlDocument | ProcessedPdfDocument;
}

export enum HowlerState {
  Loading = 'loading',
  Loaded = 'loaded',
  Unloaded = 'unloaded',
}

export interface TrackAdjustment {
  forward: boolean;
  automatic: boolean;
}

export enum DocOffsetAdjustmentReason {
  Initialized = 'Initialized',
  ContextMenu = 'ContextMenu',
  TrackAdjustment = 'TrackAdjustment',
  EpubPageChange = 'EpubPageChange',
}

export interface DocOffsetAdjustment {
  offset: string;
  reason: DocOffsetAdjustmentReason;
}

export enum AudioState {
  Missing = 'missing',
  Generating = 'generating',
  Loading = 'loading',
  Loaded = 'loaded',
  Unloaded = 'unloaded',
}

export interface AudioSource {
  audio_index: string;
  audio_src: string;
  audio_status?: string;
}

export interface AudioFragment {
  audio_index?: string; // e.g. `${page_number}--${fragment_id}`
  text: string; // e.g. 'That is my message to you, comrades: Rebellion!'
  voice_id: string; // e.g. 'Arthur'
}

export interface AudioInput {
  doc_id: string;
  audio_fragments: AudioFragment[];
}

export interface DocInput {
  doc_id: string;
}
