import { useFetchWrapper } from "../Helpers/fetchWrapper";
import ConfigFactory from "../Helpers/ConfigFactory";

export default async function checkIfDocumentExists(doc_id: string): Promise<{exists:boolean}> {
  const fetchWrapper = useFetchWrapper().post;
  const body = {
    doc_id,
  };
  const endpoint = ConfigFactory.getBackendEndpoint() + "/v2/checkDocument";
  return await fetchWrapper(endpoint, body);
}
