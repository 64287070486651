import {
  useIsoFetchWrapper,
  RateLimitError,
  AbortedError,
} from './isoFetchWrapper';
import AuthService from '../Services/AuthService';
import { NoSuchUserError } from '../models/user';

//ZZ_TRANSFER: AUTH
function useFetchWrapper(options = {}) {
  let isRefreshing = false;
  let retry = 0;
  const retryLimit = 1;

  const getAccessToken = async () => {
    const session = await fetch('/api/auth/session').then(res => res.json());
    // console.log(`getAccessToken called from fetchWrapper with accessToken`,  user?.accessToken);
    return session?.accessToken || '';
  };

  const onAuthorizationFailed = async (
    fetchWrapper: any,
    method: string,
    url: string,
    body: any,
    status: number
  ) => {
    if (isRefreshing) {
      // we are the one responsible for refresh
      console.log(
        `Skipping retry for url ${url} post-failure due to prior request`
      );
      return Promise.reject(new AbortedError('isRefreshing'));
    }
    console.log(
      `Trigger retry for url ${url} post-failure after status ${status}`
    );
    try {
      console.log(
        `Ask AuthService to refreshToken for url ${url} post-failure`
      );
      isRefreshing = true;
      const { default: askForRefreshToken } = await import(
        '../Services/Auth/refreshToken'
      );
      await askForRefreshToken(url);
    } catch (err) {
      if (err instanceof NoSuchUserError) {
        throw err;
      } else {
        console.error('onAuthorizationFailed: refreshToken failed ', err);
      }
    } finally {
      isRefreshing = false;
    }
    // Set next retry
    retry += 1;

    if (retry > retryLimit) {
      const err = `Suspending retries after ${
        retry - 1
      } retries for url:${url}`;
      console.error(err);
      return Promise.reject(new AbortedError(err));
    }
    return await fetchWrapper(method, url, body);
  };
  return useIsoFetchWrapper(options, getAccessToken, onAuthorizationFailed);
}

export { useFetchWrapper, RateLimitError };
