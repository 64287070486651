import ConfigFactory from "../Helpers/ConfigFactory";
import { FetchParams } from "@/Services/fetchBase";
import clientFetch from "@/Services/clientFetch";
import { Session } from "next-auth";
import serverFetch from "@/Services/serverFetch";

/**
 *This function should be able to get a particular document from the server
 * using the user's jwt token and the document_id of the document
 **/
export async function getDocument(document_id: string, tag: string, useClientFetch = false, session?: Session, update?: () => Promise<Session | null>) {
  
  const options: FetchParams = {
    url: '',
    includeAccessToken: true,
    options: {
      next: {
        revalidate: 1,
        tags: [`interactive-view-${tag}`],
      }
    },
  }
  
  let resp;
  
  if(useClientFetch && session && update) {
    options.url = ConfigFactory.getBackendEndpoint() + "/document/" + document_id.replace(/#/g, "%23");
    resp = await clientFetch(options, session, update);
  } else {
    options.url = process.env.BACKEND_ENDPOINT + "/document/" + document_id.replace(/#/g, "%23");
    resp = await serverFetch(options);
  }
  return resp?.data?.doc;
}
