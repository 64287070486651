// MARK: host name for allow control origin headers
// NOTE: due to preview subdomains, we should use NEXT_PUBLIC_ALLOWED_HOST for strict checking
const HOSTNAME = process.env.NEXT_PUBLIC_VERCEL_URL || 'localhost:3000';

const PROTOCOL = process.env.NEXT_PUBLIC_PROTOCOL || 'https';

export const FRONTEND_ENDPOINT = `${PROTOCOL}://${HOSTNAME}`;

// MARK: Backend endpoint (not exported)
const BACKEND_ENDPOINT = process.env.NEXT_PUBLIC_backend_endpoint;

export class ConfigSetupError extends Error {}

// MARK: the getBackendEndpoint is our proxy
export const getBackendEndpoint = () => {
  if (!BACKEND_ENDPOINT) {
    throw new ConfigSetupError(`env var NEXT_PUBLIC_backend_endpoint not set!`);
  }
  return `/api/prod`; // MARK: we proxy the BACKEND_ENDPOINT in config
};

// MARK: this endpoint is not proxied and you should not call this unless you know exactly why to use this...
export const getUnsafeBackendEndpoint = () => {
  if (!BACKEND_ENDPOINT) {
    throw new ConfigSetupError(`env var NEXT_PUBLIC_backend_endpoint not set!`);
  }
  return BACKEND_ENDPOINT;
};

const ConfigFactory = {
  getBackendEndpoint,
  getUnsafeBackendEndpoint,
};

export default ConfigFactory;
