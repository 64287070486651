import type {
    InitiateAuthCommandInput,
    InitiateAuthCommandOutput,
} from "@aws-sdk/client-cognito-identity-provider";
import { InitiateAuthCommand } from "@aws-sdk/client-cognito-identity-provider";
import { cognitoClient } from "../../clients";
import { NoSuchUserError } from "../../models/user";
import { getUser, setLocalStorageUser } from "../localStorage";
import AuthService from "../AuthService";

let isRefreshing = false;
let refreshPromise: Promise<InitiateAuthCommandOutput> | Promise<void> = Promise.resolve();

const askForRefreshToken = async (serviceName: string) => {
  const user = getUser();
  if (user === null) {
    throw new NoSuchUserError("No user");
  }
  const refreshToken = user.refreshToken || "";
  const params: InitiateAuthCommandInput = {
    AuthFlow: "REFRESH_TOKEN_AUTH",
    ClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
    AuthParameters: {
      REFRESH_TOKEN: refreshToken,
    },
  };
  const start = performance.now()
  if(isRefreshing) {
    console.log(`side request from ${serviceName} waiting for prior refreshToken`)
    await refreshPromise
    console.log(`side request from ${serviceName} borrows accessToken after ${Math.floor(performance.now() - start)} ms`);
    return getUser()?.accessToken
  }
  let accessToken = ''
  let idToken = ''
  try {
    console.log(`Asking for refreshToken for ${serviceName}`);
    isRefreshing = true
    refreshPromise = cognitoClient.send(new InitiateAuthCommand(params))
    const res = await refreshPromise;
    refreshPromise = Promise.resolve()
    accessToken = res.AuthenticationResult?.AccessToken || ''
    idToken = res.AuthenticationResult?.IdToken || ''

    console.log(`received accessToken for ${serviceName} after ${Math.floor(performance.now() - start)} ms`);
    setLocalStorageUser(AuthService.buildUserObject(accessToken, idToken, refreshToken));
  } catch(err) {
    console.error(`Failed InitiateAuthCommand for ${serviceName}`, err)
  } finally {
    isRefreshing = false
    refreshPromise = Promise.resolve()
  }
  return accessToken
};

export default askForRefreshToken;