import jwt from 'jsonwebtoken';

export const getExpiration = (token: string): number | null => {
  try {
    const decodedToken: any = jwt.decode(token);
    return decodedToken?.exp ?? null;
  } catch (err) {
    console.error('An error occurred while decoding the token', err);
    return null;
  }
};
