import ConfigFactory from "../Helpers/ConfigFactory";
import type { DocumentAccess } from "../models/document"
import clientFetch from "@/Services/clientFetch";
import { Session } from "next-auth";
import { FetchParams } from "@/Services/fetchBase";

export default async function shareDocument(
  requested_doc_access: DocumentAccess,
  doc_id: string,
  session: Session,
  update: () => Promise<Session | null>
) {
  const endpoint = ConfigFactory.getBackendEndpoint() + "/document";
  const body = {
    doc_access: requested_doc_access,
    document_id: doc_id
  };
  const params: FetchParams = {
    url: endpoint,
    includeAccessToken: true,
    options: {
      method: "PUT",
      body: body,
      headers: {
        "Content-Type": "application/json",
      },
    }
  }
  const resp = await clientFetch(params, session, update);
  return resp;
}
