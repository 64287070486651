import * as PolyfillBuffer from 'buffer';
import type { NextRequest } from 'next/server';
import * as jose from 'jose';
import { fetchWellKnownKeys, getCognitoBaseUrl } from './CognitoAuthHelper';
import { User } from '../models/user';
import { cognitoClient } from '../clients';
import {
  CognitoIdentityProviderServiceException,
  InitiateAuthCommand,
  InitiateAuthCommandInput,
  InitiateAuthCommandOutput,
} from '@aws-sdk/client-cognito-identity-provider';
import AuthService from '@/Services/AuthService';
import { setLocalStorageUser } from '@/Services/localStorage';
import crypto from 'crypto';

// MARK: Lightweight version of AuthService that depends on fewer libs for vercel edge usage
//ZZ_TRANSFER: AUTH

const decodeTokenHeader = (token: string) => {
  const [headerEncoded] = token.split('.');
  const buff = PolyfillBuffer.Buffer.from(headerEncoded, 'base64');
  const text = buff.toString('ascii');
  return JSON.parse(text);
};

export class MissingAuthError extends Error {}
export class MissingPublicKeyError extends Error {}
export class PublicKeyMismatchError extends Error {}
export class TokenExpiryError extends Error {}
export class TokenSignatureError extends Error {}
export class UnknownTokenError extends Error {}

export interface SecureInfo {
  jwt: string;
  username: string;
  exp: number;
  groups: string[];
}

export interface JWTClaimValidationFailed {
  code: string;
}

type KeyStore = jose.JWK[];

export const parseSecureInfo = async (jwt: string) => {
  if (!jwt) {
    console.log(`Missing auth header!`);
    throw new MissingAuthError('MissingAuth');
  }
  const { kid } = decodeTokenHeader(jwt);
  let jsonWebKeys: KeyStore = await fetchWellKnownKeys();
  if (!jsonWebKeys) {
    console.log(`Could not get jsonWebKeys from cognito`);
    throw new MissingPublicKeyError('MissingPublicKey');
  }
  return await parseSecureInfoWithKeys(jwt, kid, jsonWebKeys);
};

export const parseSecureInfoFromRequest = async (req: NextRequest) => {
  console.log(`parseSecureInfoFromRequest from: ${req.url} -> ${req.nextUrl}`);
  let jwt = parseJwtFromHeader(req);
  return await parseSecureInfo(jwt);
};

export const parseSecureInfoFromUser = async (user: User) => {
  const jwt = user.accessToken;
  return await parseSecureInfo(jwt);
};

export const parseSecureInfoWithKeys = async (
  jwt: string,
  kid: string,
  jsonWebKeys: KeyStore
) => {
  // console.log(`kid: ${kid}`)
  const jwtMatch = jsonWebKeys.find(({ kid: candidate }) => candidate === kid);
  if (!jwtMatch) {
    console.log(`Could not locate kid:${kid} from jwt_token:${jwt}`);
    throw new PublicKeyMismatchError('PublicKeyMismatch');
  }
  // console.log(`jwtMatch kid: ${jwtMatch.kid}`)
  const publicKey = await jose.importJWK(jwtMatch, 'RS256');
  let decoded;
  try {
    decoded = await jose.jwtVerify(jwt, publicKey, {
      issuer: getCognitoBaseUrl(),
    });
  } catch (err) {
    if ((err as JWTClaimValidationFailed).code == 'ERR_JWT_EXPIRED') {
      console.log(`ERR_JWT_EXPIRED`);
      throw new TokenExpiryError('Try Refreshing');
    } else if (
      (err as JWTClaimValidationFailed).code ==
      'ERR_JWS_SIGNATURE_VERIFICATION_FAILED'
    ) {
      console.log(`ERR_JWS_SIGNATURE_VERIFICATION_FAILED`);
      throw new TokenSignatureError('Try Logging in again');
    } else {
      console.error(`JSON webkey failed validation`, err);
      throw new UnknownTokenError('Try Logging in again');
    }
  }
  const { payload, protectedHeader } = decoded;
  const { 'cognito:groups': groups = [], username, exp } = payload;

  return { username, groups, exp, jwt } as SecureInfo;
};

export const parseJwtFromCookies = (req: NextRequest) => {
  const jwt =
    req.cookies.get('authorization')?.value || // http
    req.cookies.get('accessToken')?.value; // js
  console.log('auth cookie', jwt);
  return jwt;
};

export const parseJwtFromHeader = (req: NextRequest) => {
  const auth_header = req.headers.get('Authorization') || '';
  const jwt = auth_header && auth_header.split('Bearer ')[1];
  console.log('auth header', jwt);
  return jwt;
};

export const login = async (username: string, password: string) => {
  const params: InitiateAuthCommandInput = {
    AuthFlow: 'USER_PASSWORD_AUTH',
    ClientId: process.env.COGNITO_CLIENT_ID,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
      SECRET_HASH: getCognitoSecretHash(
        process.env.COGNITO_CLIENT_ID!,
        process.env.COGNITO_CLIENT_SECRET!,
        username
      ),
    },
  };

  return await cognitoClient
    .send(new InitiateAuthCommand(params))
    .then((response: InitiateAuthCommandOutput) => {
      const idToken = response.AuthenticationResult?.IdToken!;
      const accessToken = response.AuthenticationResult?.AccessToken!;
      const refreshToken = response.AuthenticationResult?.RefreshToken!;
      return AuthService.buildUserObject(accessToken, idToken, refreshToken);
    })
    .catch((err: CognitoIdentityProviderServiceException) => {
      return Promise.reject(err);
    });
};

export const getCognitoSecretHash = (
  clientId: string,
  clientSecret: string,
  username: string
) => {
  const secretHash = crypto
    .createHmac('sha256', clientSecret)
    .update(`${username}${clientId}`)
    .digest('base64');

  return secretHash;
};
