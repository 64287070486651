export interface User {
  email: string;
  name: string;
  sub: string;
  emailVerified: boolean;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  loggedIn: boolean;
  initialized: boolean;
  groups: [string];
  exp: number;
}

export class NoSuchUserError extends Error {
  code: string;
  constructor(message: string) {
    super(message);
    this.name = 'NoSuchUserError';
    this.code = '401';
  }
}
