import {
  createAsyncThunk,
  createSlice,
  miniSerializeError,
  PayloadAction,
} from '@reduxjs/toolkit';
import { backOff } from 'exponential-backoff';
import { log } from '@logtail/next';
import * as Sentry from '@sentry/nextjs';
import type { DocumentInfo } from '../models/documentInfo';
import { AudioState } from '../models/document';
import { NoSuchUserError } from '../models/user';
import type {
  ProcessedPdfDocument,
  ProcessedUrlDocument,
} from '../models/document';
import { isGuestOrIsSharedWith } from '../Helpers/listeningHelpers';
import { NotAuthorizedError } from '../Helpers/isoFetchWrapper';
import shareDocument from '../Services/shareDocument';
import {
  DocumentAccess,
  TrackAdjustment,
  DocOffsetAdjustment,
  DocOffsetAdjustmentReason,
} from '../models/document';
import { getDocument } from '../Services/getDocument';
import {
  getLocalStorageOffsetForDocument,
  getUser,
} from '../Services/localStorage';
import trackDocumentOffset from '../Services/trackDocumentOffset';
// import updateLastOpenedTime from '../Services/updateLastOpenedTime';
import processAudio from '../Services/processAudio';
import { Session } from 'next-auth';
import { ChatAudioBlob } from '@/components/InteractiveView/Chat/Types';

interface AudioControlsState {
  playback_speed: number;
  isPlaying: boolean;
  isChatPlaying: boolean;
  unplayable: boolean;
  listenAudioDelta: number;
  listenAudioState: AudioState;
  doc?: DocumentInfo;
  voice: string;
  authError: string | null;
  loadingContent: boolean;
  errorLoadingContent: boolean;
  errorLoadingAudio: boolean;
  mainAudioReady: boolean;
  mainAudioEnded: boolean;
  audioMap: any;
  pageSectionMap: any;
  enqueued_audio_map: any;
  viewingPage: number;
  initialOffset: string;
  prevOffset: string;
  spanIdsToDocOffsetMap: any;
  sharing: boolean;
  access?: 'owner' | 'viewer' | 'none';
  isLoadingChatAudio: boolean;
  chatListenIndex: number;
  chatAudioBlob?: ChatAudioBlob;
}

const initialState: AudioControlsState = {
  playback_speed: 1.0,
  isPlaying: false,
  isChatPlaying: false,
  unplayable: false,
  listenAudioState: AudioState.Missing,
  listenAudioDelta: 0,
  doc: undefined,
  voice: 'Arthur',
  authError: null,
  loadingContent: true,
  errorLoadingContent: false,
  errorLoadingAudio: false,
  mainAudioReady: false,
  mainAudioEnded: false,
  audioMap: {},
  pageSectionMap: {},
  enqueued_audio_map: {},
  viewingPage: 1, // MARK: IMPORTANT pdf.js will not emit a handlePageChange event on first load
  initialOffset: '',
  prevOffset: '',
  spanIdsToDocOffsetMap: {},
  sharing: false,
  access: undefined,
  isLoadingChatAudio: false,
  chatListenIndex: -1,
  chatAudioBlob: undefined,
};

// export const wrappedCall = async (
//   request: () => Promise<any>,
//   doc_id: string
// ) => {
//   try {
//     return await backOff(request, {
//       numOfAttempts: 4,
//       retry: (err: any, attemptNumber: number) => {
//         log.error(
//           `wrappedCall attempt: ${attemptNumber} failed, err: ${err} for doc_id ${doc_id}`,
//           { doc_id }
//         );
//         if (err instanceof NoSuchUserError) {
//           throw err;
//         } else if (err instanceof NotAuthorizedError) {
//           throw err;
//         } else {
//           return true;
//         }
//       },
//     });
//   } catch (err) {
//     log.error(`wrappedCall err: ${err} for doc_id ${doc_id}`, { doc_id });
//     throw err;
//   }
// };

export const wrappedGetDocument = async (doc_id: string) => {
  try {
    return await backOff(() => getDocument(doc_id), {
      numOfAttempts: 4,
      retry: (err: any, attemptNumber: number) => {
        log.error(
          `getDocument attempt: ${attemptNumber} failed, err: ${err} for doc_id ${doc_id}`,
          { doc_id }
        );
        if (err instanceof NoSuchUserError) {
          throw err;
        } else if (err instanceof NotAuthorizedError) {
          throw err;
        } else {
          return true;
        }
      },
    });
  } catch (err) {
    log.error(`wrappedGetDocument err: ${err} for doc_id ${doc_id}`, {
      doc_id,
    });
    Sentry.captureException(err);
    throw err;
  }
};

// const wrappedProcessAudio = async (doc_id: string, audioFragments: any) => {
//   try {
//     return await backOff(() => processAudio(doc_id, audioFragments), {
//       numOfAttempts: 4,
//       retry: (err: any, attemptNumber: number) => {
//         log.error(
//           `wrappedProcessAudio attempt: ${attemptNumber} failed, err: ${err} for doc_id ${doc_id}`,
//           { doc_id }
//         );
//         if (err instanceof NoSuchUserError) {
//           throw err;
//         } else if (err instanceof NotAuthorizedError) {
//           throw err;
//         } else {
//           return true;
//         }
//       },
//     });
//   } catch (err) {
//     log.error(`wrappedProcessAudio err: ${err} for doc_id ${doc_id}`, {
//       doc_id,
//     });
//     Sentry.captureException(err);
//     throw err;
//   }
// };
//
// export const getDoc = createAsyncThunk(
//   'audioControls/getDoc',
//   async (input: { id: string }, thunkAPI) => {
//     try {
//       const response = await wrappedGetDocument(input.id);
//       console.log('getDoc response', response);
//       return response.data.doc;
//     } catch (err) {
//       log.error(
//         `wrappedGetDocument failed for ${input.id} with err: ${err} for doc_id ${input.id}`,
//         { doc_id: input.id }
//       );
//       if (err instanceof NoSuchUserError) {
//         return thunkAPI.rejectWithValue('NoSuchUserError');
//       } else if (err instanceof NotAuthorizedError) {
//         return thunkAPI.rejectWithValue('NotAuthorizedError');
//       } else {
//         return thunkAPI.rejectWithValue((err as Error).name || 'UnknownError');
//       }
//     }
//   }
// );

export const processAudioThunk = createAsyncThunk(
  'audioControls/processAudio',
  async (
    input: {
      id: string;
      audioFragments: any;
      session: Session | null;
      update: () => Promise<Session | null>;
    },
    thunkAPI
  ) => {
    try {
      const response = await processAudio(
        input.id,
        input.audioFragments,
        input.session,
        input.update
      );
      return response;
    } catch (err) {
      log.error(
        `wrappedProcessAudio failed for ${input.id} with err: ${err} for doc_id ${input.id}`,
        { doc_id: input.id }
      );
      if (err instanceof NoSuchUserError) {
        return thunkAPI.rejectWithValue('NoSuchUserError');
      } else if (err instanceof NotAuthorizedError) {
        return thunkAPI.rejectWithValue('NotAuthorizedError');
      } else {
        return thunkAPI.rejectWithValue((err as Error).name || 'UnknownError');
      }
    }
  }
);

// export const shareDocumentThunk = createAsyncThunk(
//   'audioControls/shareDocument',
//   async (input: { doc_id: string; doc_access: DocumentAccess }, thunkAPI) => {
//     try {
//       const response = await wrappedCall(
//         () => shareDocument(input.doc_access, input.doc_id),
//         input.doc_id
//       );
//       return response;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(miniSerializeError(err));
//     }
//   }
// );

const audioControls = createSlice({
  name: 'audioControls',
  initialState: initialState,
  reducers: {
    updatePlaybackSpeed: (state, action: PayloadAction<number>) => {
      state.playback_speed = action.payload;
    },
    updateVoice: (state, action: PayloadAction<string>) => {
      state.voice = action.payload;
    },
    updateSectionMap: (state, action) => {
      const { page_section_map } = action.payload;
      state.pageSectionMap = page_section_map;
    },
    updatePageId: (state, action) => {
      const pageId = action.payload;
      window._viewingPage = pageId;
      state.viewingPage = pageId;
    },
    updateDocOffset: state => {
      state.listenAudioDelta = 0;
      state.mainAudioEnded = false;
    },
    updateDocPageNumbers: (state, action: PayloadAction<number>) => {
      if (!state.doc) {
        return;
      }
      state.doc.pages = action.payload;
    },
    adjustTrackForward: (state, action: PayloadAction<TrackAdjustment>) => {
      const { forward, automatic } = action.payload;
      state.listenAudioDelta = 2 * Number(forward) - 1;
    },
    setListenAudioState: (state, action: PayloadAction<AudioState>) => {
      log.info(`redux: listenAudioState: ${action.payload}`);
      state.listenAudioState = action.payload;
      state.mainAudioReady =
        state.mainAudioReady || action.payload == AudioState.Loaded;
    },
    setMainAudioReady: (state, action: PayloadAction<boolean>) => {
      log.debug(`redux: mainAudioReady: ${action.payload}`);
      state.mainAudioReady = action.payload;
    },
    setMainAudioEnded: (state, action: PayloadAction<boolean>) => {
      state.mainAudioEnded = action.payload;
    },
    setUplayable: (state, action: PayloadAction<boolean>) => {
      state.unplayable = action.payload;
    },
    // stopAudioForTabs: state => {
    //   state.isPlaying = false;
    // },
    requestPlay: (state, action: PayloadAction<boolean>) => {
      if (action.payload && state.isChatPlaying) {
        state.isChatPlaying = false;
      }
      log.info(`redux: requestPlay: ${action.payload}`);
      state.isPlaying = action.payload;
      state.mainAudioEnded = false;
    },
    setIsChatPlaying: (state, action: PayloadAction<boolean>) => {
      if (action.payload && state.isPlaying) {
        state.isPlaying = false;
      }
      state.isChatPlaying = action.payload;
    },
    setSpanIdsToDocOffsetMap: (state, action: PayloadAction<any>) => {
      log.debug(`redux: setSpanIdsToDocOffsetMap`, action.payload);
      state.spanIdsToDocOffsetMap = action.payload;
    },
    renameDoc: (state, action: PayloadAction<string>) => {
      if (!state.doc) {
        return;
      }
      console.log('renameDoc', action.payload);
      state.doc.title = action.payload;
    },
    setIsLoadingChatAudio: (state, action: PayloadAction<boolean>) => {
      state.isLoadingChatAudio = action.payload;
    },
    setChatListenIndex: (state, action: PayloadAction<number>) => {
      state.isChatPlaying = false;
      state.chatListenIndex = action.payload;
    },
    setChatAudioBlob: (state, action: PayloadAction<ChatAudioBlob>) => {
      state.chatAudioBlob = action.payload;
    },
    resetState: (state, action: PayloadAction<string>) => {
      return { ...initialState, doc: undefined, docId: action.payload };
    },
  },
  extraReducers: builder => {
    //   builder.addCase(getDoc.fulfilled, (state, { payload }) => {
    //     const document = payload as ProcessedPdfDocument & ProcessedUrlDocument;
    //     console.log('getDoc.fulfilled', document);
    //     const doc: DocumentInfo = {
    //       id: document.document_id,
    //       title: document.doc_title,
    //       access: document.doc_access,
    //       added: document.date_added,
    //       image: document.doc_image,
    //       status: document.doc_status,
    //       type: document.doc_type,
    //       parent: document.parent_folder,
    //       content: document.doc_content || '',
    //       description: '',
    //       pages: document.num_pages,
    //       lastOpened: document.last_opened_time,
    //       offset: document.doc_offset || '',
    //       userId: document.user_id || '',
    //       encoding: document.encoding,
    //       mimetype: document.mimetype,
    //       size: ~~document.size,
    //       detected_lang: document.detected_lang || '',
    //     };
    //     // MARK: we cannot use the owner's doc offset and use local offset
    //     const localOffset = getLocalStorageOffsetForDocument(doc.id);
    //     if (isGuestOrIsSharedWith(doc.userId)) {
    //       doc.offset = localOffset;
    //     } else if (localOffset > doc.offset) {
    //       // MARK: local offset is most recent
    //       doc.offset = localOffset;
    //     }
    //     if (doc.userId === getUser()?.sub) state.access = 'owner';
    //     else if (doc.access === 'public') state.access = 'viewer';
    //     else state.access = 'none';
    //     state.initialOffset = doc.offset;
    //     state.doc = doc;
    //     state.voice = findDefaultVoice(doc.detected_lang);
    //     state.loadingContent = false;
    //     state.errorLoadingContent = false;
    //     // updateLastOpenedTime(doc.id, doc.userId);
    //   });
    //
    //   builder.addCase(getDoc.pending, (state, action) => {
    //     state.loadingContent = true;
    //     state.errorLoadingContent = false;
    //     state.access = undefined;
    //   });
    //
    //   builder.addCase(getDoc.rejected, (state, action) => {
    //     console.error(`getDoc.rejected - action:`, action);
    //     const { payload } = action;
    //     if (payload == 'NoSuchUserError') {
    //       state.authError = 'NoSuchUserError';
    //     } else if (payload == 'NotAuthorizedError') {
    //       state.authError = 'NotAuthorizedError';
    //     }
    //     state.doc = undefined;
    //     state.loadingContent = false;
    //     state.errorLoadingContent = true;
    //   });

    builder.addCase(processAudioThunk.fulfilled, (state, action) => {
      const document_id = action?.payload?.data?.doc_id;
      const audio_resp = action?.payload?.data?.audio_details || [];
      for (const audio_obj of audio_resp) {
        const {
          audio_status,
          voice_id,
          audioURI: audio_src,
          audio_index,
        } = audio_obj;

        delete state.enqueued_audio_map[document_id][voice_id][audio_index];

        if (!state.audioMap[document_id]) {
          state.audioMap[document_id] = {};
        }
        if (!state.audioMap[document_id][voice_id]) {
          state.audioMap[document_id][voice_id] = {};
        }
        state.audioMap[document_id][voice_id][audio_index] = {
          audio_src,
          audio_status,
        };
      }
    });

    builder.addCase(processAudioThunk.pending, (state, action) => {
      const audio_frags = action?.meta?.arg.audioFragments;
      const doc_id = action.meta.arg.id;
      const voice_id = state.voice;
      if (!state.enqueued_audio_map[doc_id]) {
        state.enqueued_audio_map[doc_id] = {};
      }
      if (!state.enqueued_audio_map[doc_id][voice_id]) {
        state.enqueued_audio_map[doc_id][voice_id] = {};
      }
      for (const frag of audio_frags) {
        const { audio_index } = frag;
        state.enqueued_audio_map[doc_id][voice_id][audio_index] = true;
      }
    });

    builder.addCase(processAudioThunk.rejected, (state, action) => {
      const audio_frags = action?.meta?.arg.audioFragments || [];
      const doc_id = action.meta.arg.id;
      const voice_id = state.voice;
      for (const frag of audio_frags) {
        const { audio_index } = frag;
        const obj =
          state.enqueued_audio_map?.[doc_id]?.[voice_id]?.[audio_index];
        if (obj) {
          delete state.enqueued_audio_map?.[doc_id]?.[voice_id]?.[audio_index];
        }
      }
    });

    // builder.addCase(shareDocumentThunk.fulfilled, (state, action) => {
    //   if (!state.doc) return;
    //   state.sharing = false;
    //   state.doc.access = action.meta.arg.doc_access;
    // });
    // builder.addCase(shareDocumentThunk.pending, (state, action) => {
    //   state.sharing = true;
    // });
    // builder.addCase(shareDocumentThunk.rejected, (state, action) => {
    //   state.sharing = false;
    // });
  },
});

export const {
  updatePlaybackSpeed,
  adjustTrackForward,
  updateDocOffset,
  updateDocPageNumbers,
  updatePageId,
  updateVoice,
  updateSectionMap,
  requestPlay,
  setListenAudioState,
  setMainAudioReady,
  setUplayable,
  setMainAudioEnded,
  setSpanIdsToDocOffsetMap,
  setIsChatPlaying,
  // stopAudioForTabs,
  renameDoc,
  setIsLoadingChatAudio,
  setChatListenIndex,
  setChatAudioBlob,
  resetState,
} = audioControls.actions;

export default audioControls.reducer;

export function findDefaultVoice(detected_lang: string | undefined) {
  if (detected_lang == 'english') {
    //skip since english is the default
    return 'Arthur';
  } else if (detected_lang == 'spanish') {
    return 'Mia';
  } else if (detected_lang == 'french') {
    return 'Lea';
  } else if (detected_lang == 'german') {
    return 'Vicki';
  } else if (detected_lang == 'russian') {
    return 'Tatyana';
  } else if (detected_lang == 'portuguese') {
    return 'Camila';
  } else if (detected_lang == 'korean') {
    return 'Takumi';
  } else if (detected_lang == 'arabic') {
    return 'Zeina';
  } else if (detected_lang == 'hebrew') {
    return 'Avri';
  } else if (detected_lang == 'armenian') {
    return 'Anahit';
  } else if (detected_lang == 'persian') {
    return 'Dilara';
  } else if (detected_lang == 'chinese') {
    return 'Zhiyu';
  } else if (detected_lang == 'italian') {
    return 'Bianca';
  } else if (detected_lang == 'czech') {
    return 'Antonin';
  } else if (detected_lang == 'danish') {
    return 'Jeppe';
  } else if (detected_lang == 'dutch') {
    return 'Colette';
  } else if (detected_lang == 'slovene') {
    return 'Rok';
  } else if (detected_lang == 'norweigan') {
    return 'Finn';
  } else if (detected_lang == 'romanian') {
    return 'Emil';
  } else if (detected_lang == 'hungarian') {
    return 'Tamas';
  } else {
    console.log('detected language not handled of: ', detected_lang);
    return 'Arthur';
  }
}
