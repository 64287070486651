import { configureStore } from '@reduxjs/toolkit';
import interactiveViewSlice from './interactiveViewSlice';
import userSlice from './userSlice';
import modalSlice from './modalSlice';
import audioControlsSlice from './audioControlsSlice';
import outlineSlice from './outlineSlice';
import { actionCommReducer as actionComm } from './actionCommunicationSlice';

const store = configureStore({
  reducer: {
    outlineStore: outlineSlice,
    interactiveView: interactiveViewSlice,
    user: userSlice,
    modals: modalSlice,
    audioControls: audioControlsSlice,
    actionComm: actionComm,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
