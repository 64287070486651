import {
  createAsyncThunk,
  miniSerializeError,
  createSlice,
} from '@reduxjs/toolkit';
import deleteDocument from '../Services/deleteDocument';
import shareDocument from '../Services/shareDocument';

const deleteDocumentThunk = createAsyncThunk(
  'actionComm/deleteDocument',
  async (action_payload, thunkAPI) => {
    try {
      const doc_id = action_payload?.doc_id;
      const response = await deleteDocument(doc_id);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(miniSerializeError(err));
    }
  }
);

const shareDocumentThunk = createAsyncThunk(
  'actionComm/shareDocument',
  async (action_payload, thunkAPI) => {
    try {
      const doc_access = action_payload?.doc_access;
      const doc_id = action_payload?.doc_id;
      const response = await shareDocument(doc_access, doc_id);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(miniSerializeError(err));
    }
  }
);

const actionCommSlice = createSlice({
  name: 'actionComm',
  initialState: {
    success_msg: null,
    is_loading: false,
    error_msg: null,
    is_showing_modal: false,
    doc_id: null,
    action_type: null,
    is_action_modal_showing: false,
    title: '',
    method: null,
  },
  reducers: {
    onExitModal: (state, action) => {
      state.error_msg = null;
      state.is_loading = false;
      state.success_msg = null;
      state.is_action_modal_showing = false;
      state.method = null;
    },
    handleActionClick: (state, action) => {
      console.log(`handleActionClick`, action.payload);
      state.doc_id = action.payload.doc_id;
      state.action_type = action.payload.action_type;
      state.title = action.payload.title;
      state.is_action_modal_showing = !state.is_action_modal_showing;
      state.method = action.payload.method;
    },
  },
  extraReducers: builder => {
    builder.addCase(deleteDocumentThunk.fulfilled, (state, action) => {
      console.log('deleteDocument completed');
      state.success_msg = false;
      state.is_loading = false;
      state.error_msg = null;
      state.is_action_modal_showing = false;
    });

    builder.addCase(deleteDocumentThunk.pending, (state, action) => {
      console.log('deleteDocument pending');
      state.is_loading = true;
      state.error_msg = null;
      state.success_msg = null;
    });

    builder.addCase(deleteDocumentThunk.rejected, (state, action) => {
      console.log('deleteDocument rejected');
      state.is_loading = false;
      state.success_msg = null;
      state.error_msg =
        action?.payload?.message ||
        'Please contact support with a brief message describing what you were trying to accomplish and we will get this issue resolved right away for you!';
    });
    ///
    builder.addCase(shareDocumentThunk.fulfilled, (state, action) => {
      console.log('shareDocument completed');
      state.success_msg = action.payload.success_msg;
      state.is_loading = false;
      state.error_msg = null;
    });

    builder.addCase(shareDocumentThunk.pending, (state, action) => {
      console.log('shareDocument pending');
      state.is_loading = true;
      state.error_msg = null;
      state.success_msg = null;
    });

    builder.addCase(shareDocumentThunk.rejected, (state, action) => {
      console.log('shareDocument rejected');
      state.is_loading = false;
      state.success_msg = null;
      state.error_msg =
        action?.payload?.message ||
        'Please contact support with a brief message describing what you were trying to accomplish and we will get this issue resolved right away for you!';
    });
  },
});

const { actions: actionCommActions, reducer: actionCommReducer } =
  actionCommSlice;

export {
  actionCommActions,
  actionCommReducer,
  deleteDocumentThunk,
  shareDocumentThunk,
};
