import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { v4 as uuidv4 } from 'uuid';

import type { ILanguage } from '../models/language';

import {
  setUserPreferredLanguage,
  setLocalStorageOffsetForDocumentChat,
  removeLocalStorageOffsetForDocumentChat,
  getUserPreferredLanguage,
} from '../Services/localStorage';

export enum ChatType {
  Answer = 'Answer',
  Error = 'Error',
  Question = 'Question',
  Simplify = 'Simplify',
  Explain = 'Explain',
  Translate = 'Translate',
  KeyPoints = 'Key Points',
  Welcome = 'Welcome',
}
export interface ChatSource {
  pageNumber: number;
  prefix: string;
}
export interface Chat {
  chat: string;
  chatId: string;
  sender: 'user' | 'ai';
  type: ChatType;
  extra?: string;
  language?: string;
  collapsed: boolean;
  waitingForResponse: boolean;
  sources?: ChatSource[];
}

const initialState: InteractiveViewState = {
  selectedText: '',
  language: getUserPreferredLanguage(),
  chats: [],
  docId: undefined,
  waitingForResponseToFinish: false,
  chatOpen: true,
  chatOpenMobile: false,
  chatState: 'disconnected',
  docZoom: false,
  docRotation: 0,
  docAspectRatio: undefined,
  chatConsumedPoints: undefined,
  chatRemainingPoints: undefined,
};
interface InteractiveViewState {
  selectedText: string;
  language: ILanguage;
  chats: Chat[];
  docId?: string;
  waitingForResponseToFinish: boolean;
  chatOpen: boolean;
  chatOpenMobile: boolean;
  chatState:
    | 'disconnected'
    | 'connected'
    | 'initialized'
    | 'connectionError'
    | 'noAccess'
    | 'error';
  docZoom: boolean;
  docRotation: number;
  docAspectRatio: any;
  chatConsumedPoints: number | undefined;
  chatRemainingPoints: number | undefined;
}
interface IPayload {
  docId: string;
  audience?: string; // MARK: used for only simplify
  query?: string; // MARK: used for only question
  text?: string; // MARK: optional for question
  language?: string;
  extra?: string;
  type: ChatType;
}

interface IApiFunc {
  (params: any): Promise<IApiResponse | string>;
}

interface IApiResponse {
  data: {
    summary: string;
    rate_limited: boolean;
  };
}

const interactiveViewSlice = createSlice({
  name: 'interactiveView',
  initialState: initialState,
  reducers: {
    setSelectedText: (state, action: PayloadAction<string>) => {
      state.selectedText = action.payload;
    },
    resetSelectedText: state => {
      state.selectedText = '';
    },
    setLanguage: (state, action: PayloadAction<ILanguage>) => {
      setUserPreferredLanguage(action.payload);
      state.language = action.payload;
    },
    setChats: (state, action: PayloadAction<Chat[]>) => {
      state.chats = action.payload;
    },
    collapseChat: (state, action: PayloadAction<number>) => {
      state.chats[action.payload].collapsed = true;
    },
    expandChat: (state, action: PayloadAction<number>) => {
      state.chats[action.payload].collapsed = false;
    },
    deleteChats: (state, action: PayloadAction<string>) => {
      state.chats = initialState.chats;
      removeLocalStorageOffsetForDocumentChat(action.payload);
    },
    openChat: (state, { payload }: PayloadAction<{ mobile: boolean }>) => {
      if (payload?.mobile) {
        state.chatOpenMobile = true;
        return;
      }
      state.chatOpen = true;
    },
    closeChat: (state, { payload }: PayloadAction<{ mobile: boolean }>) => {
      if (payload.mobile) {
        state.chatOpenMobile = false;
        return;
      }
      state.chatOpen = false;
    },
    sendChat: (state, action: PayloadAction<any>) => {
      const {
        type,
        text = undefined,
        query = '',
        extra = '',
        chatId = uuidv4(),
      } = action.payload;
      const chat = text || query;
      state.chats.push({
        chat,
        chatId,
        sender: 'user',
        type,
        extra,
        collapsed: false,
        waitingForResponse: false,
      });
      state.chats.push({
        chat: '',
        chatId: uuidv4(),
        sender: 'ai',
        type: ChatType.Answer,
        extra: '',
        collapsed: false,
        waitingForResponse: true,
      });
      state.waitingForResponseToFinish = true;
    },
    updateChat: (state, action: PayloadAction<any>) => {
      // add to the last chat text
      const { text = '' } = action.payload;
      const lastChat = state.chats[state.chats.length - 1];
      if (lastChat?.sender === 'user') {
        state.chats.push({
          chat: text,
          chatId: uuidv4(),
          sender: 'ai',
          type: ChatType.Answer,
          extra: '',
          collapsed: false,
          waitingForResponse: false,
        });
      } else {
        lastChat.chat = lastChat.chat + text;
        lastChat.waitingForResponse = false;
      }
      if (!state.waitingForResponseToFinish)
        state.waitingForResponseToFinish = true;
    },
    updateWaitingForResponseToFinish: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.waitingForResponseToFinish = action.payload;
    },
    chatFinished: (
      state,
      {
        payload,
      }: PayloadAction<{
        response_string: string;
        sources: object[];
        rateLimiterInfo: any;
      }>
    ) => {
      state.waitingForResponseToFinish = false;
      state.chats[state.chats.length - 1].chat = payload.response_string;
      state.chats[state.chats.length - 1].sources = payload.sources;
      setLocalStorageOffsetForDocumentChat(state.docId, state.chats);
      if (payload.rateLimiterInfo) {
        state.chatConsumedPoints = payload.rateLimiterInfo.consumedPoints;
        state.chatRemainingPoints = payload.rateLimiterInfo.remainingPoints;
      }
    },
    chatTimeout: state => {
      state.waitingForResponseToFinish = false;
      state.chats[state.chats.length - 1].chat =
        'Sorry, we are unable to process your request at this time. Please try again later.';
      state.chats[state.chats.length - 1].type = ChatType.Error;
      state.chats[state.chats.length - 1].waitingForResponse = false;
      setLocalStorageOffsetForDocumentChat(state.docId, state.chats);
    },
    setLastChatToError: (state, { payload }: PayloadAction<string>) => {
      if (
        !state.chats[state.chats.length - 1]?.waitingForResponse &&
        !state.waitingForResponseToFinish &&
        state.chats[state.chats.length - 1].chatId !== 'SystemWelcome'
      )
        return;
      state.waitingForResponseToFinish = false;
      state.chats[state.chats.length - 1].type = ChatType.Error;
      state.chats[state.chats.length - 1].chat = payload;
      state.chats[state.chats.length - 1].waitingForResponse = false;
      setLocalStorageOffsetForDocumentChat(state.docId!, state.chats);
    },
    toggleChat: (state, { payload }: PayloadAction<{ mobile: boolean }>) => {
      console.log('toggleChat', payload, state.chatOpen, state.chatOpenMobile);
      if (payload.mobile) {
        state.chatOpenMobile = !state.chatOpenMobile;
        return;
      }
      state.chatOpen = !state.chatOpen;
    },
    setDocId: (state, action: PayloadAction<string>) => {
      state.docId = action.payload;
    },
    setChatState: (
      state,
      action: PayloadAction<
        | 'disconnected'
        | 'connected'
        | 'initialized'
        | 'connectionError'
        | 'noAccess'
        | 'error'
      >
    ) => {
      state.chatState = action.payload;
    },
    setDocZoom: (state, action: PayloadAction<boolean>) => {
      state.docZoom = action.payload;
    },
    setDocRotation: (state, action: PayloadAction<number>) => {
      state.docRotation = action.payload;
    },
    setDocAspectRatio: (state, action: PayloadAction<number>) => {
      state.docAspectRatio = action.payload;
    },
    resetState: (state, action: PayloadAction<string>) => {
      return { ...initialState, docId: action.payload };
    },
    setChatPoints: (
      state,
      action: PayloadAction<{ consumedPoints: number; remainingPoints: number }>
    ) => {
      state.chatConsumedPoints = action.payload.consumedPoints;
      state.chatRemainingPoints = action.payload.remainingPoints;
    },
  },
  extraReducers: builder => {},
});

export const {
  setChats,
  setLanguage,
  setSelectedText,
  resetSelectedText,
  collapseChat,
  expandChat,
  deleteChats,
  openChat,
  closeChat,
  toggleChat,
  sendChat,
  updateChat,
  updateWaitingForResponseToFinish,
  chatFinished,
  chatTimeout,
  setDocId,
  setLastChatToError,
  setChatState,
  setDocZoom,
  setDocRotation,
  setDocAspectRatio,
  resetState,
  setChatPoints,
} = interactiveViewSlice.actions;

export default interactiveViewSlice.reducer;
