import { Buffer } from "buffer";
import store from "../redux/store";
import {setUser, setLoggedIn, setInitialized, resetUser} from "../redux/userSlice";
import {User, NoSuchUserError} from "../models/user";
import { getUser, setLocalStorageUser } from "@/Services/localStorage";
import askForRefreshToken from "./Auth/refreshToken";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context";
import { signOut } from "next-auth/react";
import * as Sentry from "@sentry/nextjs";
import { Session } from "next-auth";
//ZZ_TRANSFER: AUTH

const buildUserObject = (accessToken: string, idToken: string, refreshToken: string) => {
  const {
    email: username,
    name,
    email_verified,
    sub,
    "cognito:groups": groups,
  } = parseIdToken(idToken);
  
  const user: User = {
    email: username,
    name: name,
    sub: sub,
    emailVerified: email_verified,
    accessToken: accessToken,
    refreshToken: refreshToken,
    idToken: idToken,
    loggedIn: true,
    initialized: true,
    groups: groups || [],
    exp: parseIdToken(accessToken).exp,
  };
  return user
}

// MARK: logout is marked as async but currently we don't need to do anything async here 
const logout =  async (router?: AppRouterInstance) => {
  console.log(`AuthService clear called`);
  const resp = await signOut({ redirect: false })
    .then(() => {
      console.log(`AuthService 1`);
      return fetch("/api/signout");
    }).then((resp) => resp.json())
    .catch((e) => {
    console.log("Signout error: ", e);
    Sentry.captureException(e);
  })
  if(router) {
    router.push(resp.url);
  }
  return resp;
};

const isPaying = (session?: Session | null): boolean => {
  const user = session ? session?.user : getUser();
  if (user) {
    return (user.groups?.includes('voca-plan-1') || false);
  }
  return false;
};

const parseIdToken = (idToken: string) => {
  const formattedToken = idToken.split(".");
  const raw = Buffer.from(formattedToken[1], "base64").toString()
  console.log(`raw data: ${raw}`);
  return JSON.parse(raw);
};

const updateStore = (user: User) => {
  const dispatch = store.dispatch;
  console.log(`AuthService dispatch setUser`)
  dispatch(setUser({...user, ...{initialized: true, loggedIn: true }}));
}

const optimisticInit = () => {
  const dispatch = store.dispatch;
  const user = getUser();
  dispatch(setInitialized(true));
  if (user) {
    updateStore(user);
    dispatch(setLoggedIn(true));
    return true
  }
  return false
};

const refreshAccessToken = async () => {
  const user = getUser();
  if (user) {
    await askForRefreshToken(user.refreshToken).then((accessToken) => {
      const updatedUser = getUser()
      if (updatedUser) {
        updateStore(updatedUser);
      }
    });
  }
}

export default {
  buildUserObject,
  logout,
  getUser,
  setLocalStorageUser,
  optimisticInit,
  updateStore,
  NoSuchUserError,
  isPaying,
  refreshAccessToken,
};
