import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProcessedOutline } from '../models/document';
import generateFullOutline from '../Services/generateFullOutline';
import { NoSuchUserError } from '../models/user';
import { NotAuthorizedError, RateLimitError } from '../Helpers/isoFetchWrapper';
import { wrappedGetDocument } from './audioControlsSlice';
import checkIfDocumentExists from '../Services/checkIfDocumentExists';

export interface OutlineState {
  outline: any;
  outlineState:
    | 'loading'
    | 'ready'
    | 'error'
    | 'generating'
    | 'waiting'
    | 'rate_limited'
    | 'premium_gated';
}
const initialState: OutlineState = {
  outline: undefined,
  outlineState: 'loading',
};

export const getOutline = createAsyncThunk(
  'interactiveView/getOutline',
  async (
    input: { id: string; access: 'owner' | 'viewer' | 'none' },
    thunkAPI
  ) => {
    try {
      if (input.access === 'none') {
        return thunkAPI.rejectWithValue('NotAuthorizedError');
      }
      const doc = await checkIfDocumentExists(input.id + '#outline');
      const exists = doc?.exists;
      if (!exists && input.access === 'owner') {
        const response = await generateFullOutline(input.id);
        return response.data.doc;
      }
      if (exists) {
        const response = await wrappedGetDocument(input.id + '#outline');
        const is_premium_gated =
          response?.data?.doc?.doc_status === 'premium_gated';
        if (is_premium_gated) {
          return thunkAPI.rejectWithValue('PremiumGatedError');
        }
        return response.data?.doc;
      }
      return thunkAPI.rejectWithValue('NotFoundError');
    } catch (err) {
      console.error(
        `getOutline failed for ${input.id} with err`,
        err,
        err instanceof RateLimitError
      );
      if (err instanceof NoSuchUserError) {
        return thunkAPI.rejectWithValue('NoSuchUserError');
      } else if (err instanceof NotAuthorizedError) {
        return thunkAPI.rejectWithValue('NotAuthorizedError');
      } else if (err instanceof RateLimitError) {
        return thunkAPI.rejectWithValue('RateLimitError');
      } else {
        return thunkAPI.rejectWithValue((err as Error).name || 'UnknownError');
      }
    }
  }
);

export const generateFullOutlineThunk = createAsyncThunk(
  'interactiveView/generateFullOutlineThunk',
  async (input: { id: string }, thunkAPI) => {
    try {
      const response = await generateFullOutline(input.id);
      return response;
    } catch (err) {
      console.error(
        `generateFullOutlineThunk failed for ${input.id} with err`,
        err,
        err instanceof RateLimitError
      );
      if (err instanceof NoSuchUserError) {
        return thunkAPI.rejectWithValue('NoSuchUserError');
      } else if (err instanceof NotAuthorizedError) {
        return thunkAPI.rejectWithValue('NotAuthorizedError');
      } else if (err instanceof RateLimitError) {
        return thunkAPI.rejectWithValue('RateLimitError');
      } else {
        return thunkAPI.rejectWithValue((err as Error).name || 'UnknownError');
      }
    }
  }
);

function setOutline(
  state: OutlineState,
  { payload }: { payload: ProcessedOutline }
) {
  if (payload?.doc_status === 'processing') {
    state.outlineState = 'generating';
  } else if (payload?.doc_status === 'ready') {
    state.outlineState = 'ready';
  } else {
    state.outlineState = 'error';
  }
  state.outline = {
    id: payload?.document_id || '',
    title: payload?.doc_title,
    type: payload?.doc_type,
    parent: payload?.parent_folder,
    content: payload?.doc_content || '',
    userId: payload?.user_id || '',
    doc_status_timestamp: payload?.doc_status_timestamp,
    doc_status: payload?.doc_status,
  };
}

const outlineViewSlice = createSlice({
  name: 'outlineState',
  initialState: initialState,
  reducers: {
    setOutlineState: (
      state,
      action: PayloadAction<
        | 'loading'
        | 'ready'
        | 'error'
        | 'generating'
        | 'waiting'
        | 'rate_limited'
        | 'premium_gated'
      >
    ) => {
      state.outlineState = action.payload;
    },
    resetOutline: state => {
      state.outline = undefined;
      state.outlineState = 'loading';
    },
  },
  extraReducers: builder => {
    builder.addCase(generateFullOutlineThunk.fulfilled, (state, action) => {
      state.outlineState = 'waiting';
      state.outline = undefined;
    });
    builder.addCase(generateFullOutlineThunk.pending, (state, action) => {
      state.outlineState = 'waiting';
    });
    builder.addCase(
      generateFullOutlineThunk.rejected,
      (state, action: PayloadAction<any>) => {
        console.error('generateFullOutlineThunk failed with', action.payload);
        if (action.payload === 'RateLimitError') {
          state.outlineState = 'rate_limited';
          return;
        }
        state.outlineState = 'error';
      }
    );
    builder.addCase(getOutline.fulfilled, setOutline);
    builder.addCase(getOutline.pending, (state, action) => {
      // if generating outline, don't show loading
      if (
        state.outlineState === 'generating' ||
        state.outlineState === 'waiting'
      ) {
        return;
      }
      state.outline = undefined;
      state.outlineState = 'loading';
    });
    builder.addCase(getOutline.rejected, (state, action) => {
      if (action.payload === 'RateLimitError') {
        state.outline = '';
        state.outlineState = 'rate_limited';
        return;
      }
      if (action.payload === 'PremiumGatedError') {
        state.outline = '';
        state.outlineState = 'premium_gated';
        return;
      }
      if (action.payload === 'NotFoundError') {
        state.outline = '## No summary has been generated yet.';
        state.outlineState = 'ready';
        return;
      }
      if (action.payload === 'RateLimitError') {
        state.outlineState = 'rate_limited';
        return;
      }
      state.outlineState = 'error';
    });
  },
});

export const { setOutlineState, resetOutline } = outlineViewSlice.actions;

export default outlineViewSlice.reducer;
