import { useFetchWrapper } from "../Helpers/fetchWrapper";
import ConfigFactory from "../Helpers/ConfigFactory";
import {AudioFragment, AudioInput} from "../models/document";
import { FetchParams } from "@/Services/fetchBase";
import clientFetch from "@/Services/clientFetch";
import { Session } from "next-auth";

/**
 * This function should be able to request numerous audio fragments
 * using the user's jwt token, the document_id of the document,
 * and a list of the voice_id, audio_index, and text combo.
 * Each back-end request currently checks access control and
 * this startup cost encourage batching on the client side
 **/

export default async function processAudio(doc_id: string, audio_fragments: AudioFragment[], session: Session | null, update: () => Promise<Session | null>) {
  const body: AudioInput = {
    doc_id,
    audio_fragments,
  };
  const endpoint = ConfigFactory.getBackendEndpoint() + "/audioZZ";
  const params: FetchParams = {
    url: endpoint,
    includeAccessToken: true,
    options: {
      method: 'POST',
      body: body,
    }
  }
  const resp = await clientFetch(params, session, update);
  // await new Promise(resolve => setTimeout(resolve, 2000)); // MARK: enable fake delay for testing
  return resp;
}
