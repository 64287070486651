import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
  is_showing_modal: boolean;
  modal_option_id: string;
  modal_location: string;
}

const initialState: ModalState = {
  is_showing_modal: false,
  modal_option_id: '',
  modal_location: '',
};

const modalSlice = createSlice({
  name: 'modals',
  initialState: initialState,
  reducers: {
    setModalShowingState: (state, action: PayloadAction<ModalState>) => {
      state.is_showing_modal = action.payload.is_showing_modal;
      state.modal_option_id = action.payload.modal_option_id;
      state.modal_location = action.payload.modal_location;
    },
    closeModalShowingState: state => {
      state.is_showing_modal = false;
      state.modal_option_id = '';
      state.modal_location = '';
    },
  },
});

export const { setModalShowingState, closeModalShowingState } =
  modalSlice.actions;

export default modalSlice.reducer;
