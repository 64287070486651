import NextAuth, { TokenSet } from 'next-auth';
import CredentialsProvider from 'next-auth/providers/credentials';
import { Provider } from 'next-auth/providers';
import { login } from '@/Helpers/AuthHelper';
const {
  NEXTAUTH_URL,
  COGNITO_REGION,
  COGNITO_DOMAIN,
  COGNITO_CLIENT_ID,
  COGNITO_USER_POOL_ID,
  COGNITO_CLIENT_SECRET,
} = process.env;
import * as Sentry from '@sentry/nextjs';
import { getExpiration } from '@/Helpers/jwtIUtil';
import jwt from 'jsonwebtoken';
import { redirect } from 'next/navigation';

// add more social providers here
type TProvider = 'Google';
function getProvider(provider: TProvider): Provider {
  return {
    // cognito_google
    id: `cognito_${provider.toLowerCase()}`,

    // CognitoGoogle
    name: `Cognito${provider}`,
    type: 'oauth',
    clientId: COGNITO_CLIENT_ID,
    clientSecret: COGNITO_CLIENT_SECRET,
    wellKnown: `https://cognito-idp.${COGNITO_REGION}.amazonaws.com/${COGNITO_USER_POOL_ID}/.well-known/openid-configuration`,
    authorization: {
      url: `${COGNITO_DOMAIN}/oauth2/authorize`,
      params: {
        response_type: 'code',
        client_id: COGNITO_CLIENT_ID,
        identity_provider: provider,
        redirect_uri: `${NEXTAUTH_URL}/api/auth/callback/cognito_${provider.toLowerCase()}`,
      },
    },
    token: {
      url: `${COGNITO_DOMAIN}/oauth2/token`,
      params: {
        grant_type: 'authorization_code',
        client_id: COGNITO_CLIENT_ID,
        client_secret: COGNITO_CLIENT_SECRET,
        redirect_uri: `${NEXTAUTH_URL}/api/auth/callback/cognito_${provider.toLowerCase()}`,
      },
    },
    userinfo: {
      url: `${COGNITO_DOMAIN}/oauth2/userInfo`,
    },
    checks: 'nonce',
    profile: (profile: any) => {
      return { ...profile, id: profile.sub };
    },
  };
}

export const refreshAccessToken = async (token: TokenSet) => {
  try {
    const response = await fetch(`${COGNITO_DOMAIN}/oauth2/token`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: COGNITO_CLIENT_ID,
        client_secret: COGNITO_CLIENT_SECRET,
        grant_type: 'refresh_token',
        refresh_token: token.refreshToken,
      } as any),
      method: 'POST',
    });

    const tokens: TokenSet = await response.json();

    if (!response.ok) throw tokens;

    const idToken = jwt.decode(tokens.id_token || '');
    let user = token.user;
    if (
      idToken &&
      typeof idToken === 'object' &&
      idToken.email &&
      idToken.name &&
      idToken.sub &&
      idToken['cognito:groups']
    ) {
      user = {
        email: idToken.email,
        name: idToken.name,
        sub: idToken.sub,
        groups: idToken['cognito:groups'],
      };
    }

    return {
      ...token,
      accessToken: tokens.access_token,
      idToken: tokens.id_token,
      expiresAt: Date.now() + Number(tokens.expires_in) * 1000,
      user,
    };
  } catch (error) {
    // Could not refresh tokens, return error
    console.error('Error refreshing access and id tokens: ', error);
    Sentry.captureException(error);
    return { ...token, error: 'RefreshTokensError' as const };
  }
};

export const authOptions = {
  providers: [
    ...['Google'].map((provider: string) => getProvider(provider as TProvider)),
    CredentialsProvider({
      name: 'Cognito',
      id: 'cognito',
      credentials: {
        username: { label: 'Username' },
        password: { label: 'Password' },
      },
      async authorize(credentials, req) {
        if (!credentials || !credentials.username || !credentials.password)
          return null;
        return (await login(credentials.username, credentials.password).catch(
          err => {
            throw new Error(err.name);
          }
        )) as any;
      },
    }),
  ],
  pages: {
    signIn: '/login',
    error: '/auth/login', // not really used (yet)
    verifyRequest: '/verify', // not really used
    newUser: '/home', // not really used
  },
  callbacks: {
    async signIn({ user, account, profile }: any) {
      return true;
    },
    async session({ session, token }: any) {
      return token;
    },
    async jwt({ token, user, account, session }: any) {
      if (session === 'refresh') {
        return refreshAccessToken(token);
      }
      if (account) {
        if (account.provider === 'cognito_google') {
          return {
            ...token,
            accessToken: account.access_token,
            idToken: account.id_token,
            refreshToken: account.refresh_token,
            expiresAt: account.expires_at,
            tokenType: 'Bearer',
            user: {
              email: user.email,
              name: user.name,
              sub: user.sub,
              groups: user['cognito:groups'],
            },
          };
        }
        if (account.provider === 'cognito') {
          return {
            ...token,
            accessToken: user.accessToken,
            idToken: user.idToken,
            refreshToken: user.refreshToken,
            expiresAt: user.exp,
            tokenType: 'Bearer',
            user: {
              email: user.email,
              name: user.name,
              sub: user.sub,
              groups: user.groups,
            },
          };
        }
      } else {
        const decodedToken: any = jwt.decode(token.idToken);
        token.user = {
          email: decodedToken.email,
          name: decodedToken.name,
          sub: decodedToken.sub,
          groups: decodedToken['cognito:groups'],
        };
      }
      const exp = getExpiration(token.accessToken) || 0;

      // If access token has not expired, return the token
      if (Date.now() / 1000 < exp) {
        return token;
      }

      // Access token has expired, try to update it
      return refreshAccessToken(token);
    },
  },
};

const handler = NextAuth(authOptions);

export { handler as GET, handler as POST };
