import { createSlice } from '@reduxjs/toolkit';
import { User } from '../models/user';

const initialState: User = {
  email: '',
  name: '',
  sub: '',
  accessToken: '',
  refreshToken: '',
  idToken: '',
  emailVerified: false,
  loggedIn: false,
  initialized: false,
  groups: [''],
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      // console.log('userSlice.ts setUser action.payload: ', action.payload)
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.sub = action.payload.sub;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.idToken = action.payload.idToken;
      state.emailVerified = action.payload.emailVerified;
      state.loggedIn = action.payload.loggedIn;
      state.initialized = action.payload.initialized;
    },
    resetUser: state => {
      state.email = initialState.email;
      state.name = initialState.name;
      state.sub = initialState.sub;
      state.accessToken = initialState.accessToken;
      state.refreshToken = initialState.refreshToken;
      state.idToken = initialState.idToken;
      state.emailVerified = initialState.emailVerified;
      state.loggedIn = initialState.loggedIn;
      state.initialized = initialState.initialized;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setIdToken: (state, action) => {
      state.idToken = action.payload;
    },
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload;
    },
    setInitialized: (state, action) => {
      state.initialized = action.payload;
    },
  },
});

export const {
  setUser,
  resetUser,
  setAccessToken,
  setRefreshToken,
  setIdToken,
  setLoggedIn,
  setInitialized,
} = userSlice.actions;

export default userSlice.reducer;
