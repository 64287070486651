import ConfigFactory, { FRONTEND_ENDPOINT } from './ConfigFactory';
import { getAnonUserId, UserIdKey } from '../Services/localStorage';

export enum StatusCodes {
  BadRequest = '400',
  MissingAuth = '401',
  NotAuthorized = '403',
  Aborted = '409',
  RateLimited = '429',
  ServerError = '500',
}

export class AbortedError extends Error {
  code: string;
  constructor(message: string) {
    super(message);
    this.name = 'AbortedError';
    this.code = StatusCodes.Aborted;
  }
}

export class RateLimitError extends Error {
  code: string;
  constructor(message: string) {
    super(message);
    this.name = 'RateLimitError';
    this.code = StatusCodes.RateLimited;
  }
}

// e.g. Alice is missing auth (e.g. credentials are expired)
export class MissingAuthError extends Error {
  code: string;
  constructor(message: string) {
    super(message);
    this.name = 'MissingAuthError';
    this.code = StatusCodes.MissingAuth;
  }
}

// e.g. Alice tries to access Bob's private doc
export class NotAuthorizedError extends Error {
  code: string;
  constructor(message: string) {
    super(message);
    this.name = 'NotAuthorizedError';
    this.code = StatusCodes.NotAuthorized;
  }
}

// e.g. Our server sent a 500 error
export class ApiServerError extends Error {
  code: string;
  constructor(message: string) {
    super(message);
    this.name = 'ApiServerError';
    this.code = StatusCodes.ServerError;
  }
}

// e.g. Our server sent some other error
export class UnexpectedApiError extends Error {
  code: string;
  message: string;
  constructor(message: string, code: string | number) {
    super(message);
    this.name = 'UnexpectedApiError';
    this.code = code.toString();
    this.message = message;
  }
}

const onAuthorizationFailedDefault = async (
  fetchWrapper: any,
  method: string,
  url: string,
  body: any,
  status: number
): Promise<any> => {
  const displayed_error = `Authorization failed for ${method} ${url} with status: ${status}`;
  console.error(displayed_error);
  if (status == 401) {
    return Promise.reject(new MissingAuthError(displayed_error));
  } else if (status == 403) {
    return Promise.reject(new NotAuthorizedError(displayed_error));
  }
  return Promise.reject(`Unknown auth error for ${url}`);
};

const getAccessTokenDefault = () => {
  const user = localStorage?.getItem('user');
  if (user) {
    try {
      return JSON.parse(user).accessToken;
    } catch {}
  }
  return null;
};

export function useIsoFetchWrapper(
  options = {},
  getAccessToken = getAccessTokenDefault,
  onAuthorizationFailed = onAuthorizationFailedDefault
) {
  const authHeader = (maybe_url: string, token: string) => {
    // return auth header with jwt if user is logged in and request is to the api url
    let isApiUrl = false;
    try {
      const url = new URL(
        maybe_url.match(/^http(s)?:\/\//)
          ? maybe_url
          : `${FRONTEND_ENDPOINT}/${maybe_url}`
      );
      const proxied_url = new URL(FRONTEND_ENDPOINT);
      const unsafe_url = new URL(ConfigFactory.getUnsafeBackendEndpoint());
      isApiUrl = url.host == proxied_url.host || url.host == unsafe_url.host;
    } catch (err) {
      console.error('Failed to parse url', maybe_url, err);
    }
    if (token && isApiUrl) {
      return { Authorization: `Bearer ${token}`, Cache: 'no-store' };
    } else {
      return null;
    }
  };

  const fetchWrapper = async (method: string, url: string, body?: object) => {
    // MARK: call accessToken dynamically since this should get updated
    const accessToken = await getAccessToken();
    const headerObj = authHeader(url, accessToken);
    console.log(`header: ${headerObj}, accessToken: ${accessToken}`);
    let requestOptions = {
      method,
      headers: {
        [UserIdKey]: getAnonUserId(),
      },
    };
    if (headerObj) {
      requestOptions = {
        ...requestOptions,
        headers: headerObj,
        credentials: 'same-origin',
      };
    }
    if (body && method != 'GET') {
      requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.body = JSON.stringify(body);
    }

    let response: void | Response;
    try {
      response = await fetch(url, requestOptions).catch(err => {
        console.error(
          `CLIENT: Fetch failed to hit with url: ${url} and err: ${err}`
        );
      });
    } catch (err) {
      console.error(
        `SERVER: Fetch failed to hit with url: ${url} and err: ${err}`
      );
    }

    if (!response) {
      console.error(`Fetch response for url: ${url} did not return any data!`);
      return Promise.reject(new Error('An unexpected error occurred'));
    }

    if (response.ok) {
      return Promise.resolve(
        response
          .clone()
          .json()
          .catch(() => response!.text())
      );
    }

    // MARK: something bad happened and the response is not ok :(
    const { status, statusText } = response;

    let displayed_error = statusText || 'An unknown error occured';

    try {
      // MARK: custom error message
      const { error_msg } = await response.json();
      displayed_error = error_msg;
    } catch (err) {
      console.error(
        `Failed to parse custom error message for url: ${url}`,
        err
      );
    }

    // MARK: rate limit hit for our api/v2 paths
    if (status === 429) {
      console.error(
        `Rate limited for url: ${url}, status: ${status}, displayed_error: ${displayed_error}`
      );
      return Promise.reject(new RateLimitError(displayed_error));
    }
    // MARK: not authorized for this particular document
    if (status === 403) {
      console.error(`Not authorized for this url: ${url}, status: ${status}`);
      return Promise.reject(new NotAuthorizedError(displayed_error));
    }
    // MARK: bad server response
    if (status == 500) {
      console.error(
        `ApiServerError error for url: ${url}, status: ${status}, displayed_error: ${displayed_error}`
      );
      return Promise.reject(new ApiServerError(displayed_error));
    }
    // MARK: early and unexpected exit
    if (!(status == 401 || status == 403)) {
      console.error(
        `Failed to fetch for url: ${url}, status: ${status}, displayed_error: ${displayed_error}`
      );
      return Promise.reject(new UnexpectedApiError(displayed_error, status));
    }
    return await onAuthorizationFailed(fetchWrapper, method, url, body, status);
  };

  const request = (method: string) => {
    return async (url: string, body?: object) => {
      return await fetchWrapper(method, url, body);
    };
  };

  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
  };
}
