import {RateLimitError, useFetchWrapper} from "../Helpers/fetchWrapper";
import ConfigFactory from "../Helpers/ConfigFactory";

export default async function generateFullOutline(doc_id: string) {
  const fetchWrapper = useFetchWrapper().post;
  const body = {
    doc_id,
  };
  // MARK: vercel will kill our request if it exceeds 30s
  const endpoint = ConfigFactory.getBackendEndpoint() + "/v2/fullOutline";
  console.log("endpoint is: ", endpoint);
  try {
    return await fetchWrapper(endpoint, body);
  }
  catch(err) {
    if(err instanceof RateLimitError) {
      console.log(`RateLimited`)
    } else{
      console.error(`generateFullOutline hit unexpected error`, err);
    }
    throw err;
  }
}
